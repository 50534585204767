video {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.video-wrapper {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    justify-content: center;
}

.header {
    position: relative;
}